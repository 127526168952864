<template>
  <div class="about-wrap">
   
    <div class="about-content">
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        // className: ''
      }
    },
    // watch: {
    //   $route:{
    //     handler({ path }) {
    //       if (path === '/about/contactUs' || path === "/about/honors") {
    //         this.className = 'test'
    //       } else {
    //         this.className = ''
    //       }
    //     },
    //     deep: true,
    //     immediate: true
    //   }
    // },
    methods: {
      // handleGoOut() {
      //   window.open('https://gs.amac.org.cn/amac-infodisc/res/pof/person/personList.html?userId=1903291909100869')
      // }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.about-wrap {
  width: 100%;
  min-height: 500px;
  position: relative;
  background: #FFFFFF;
  .nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    
    z-index: 3;
    div {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      text-align: right;
      a {
        display: inline-block;
        height: 100%;
        padding: 20px 26px;
        font-size: 18px;
        color: #fff;
        &.router-link-active {
          background: #D7AD7F;
        }
      }
    }
    &.test {
      box-shadow: 0px 4px 12px #EDF1F7;
      div a {
        color: #1F1F1F;
      }
    }
  }
}
</style>